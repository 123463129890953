<template>
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-hidden="true"
    ref="modalRefNotas"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Visualizar notas</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul class="list-group">
            <li
              v-for="(nota, index) in notas"
              :key="index"
              class="pb-0 border-0 list-group-item justify-content-between ps-0 border-radius-lg"
            >
              <div class="d-flex">
                <div class="d-flex align-items-center">
                  <button
                    class="p-3 mb-0 btn btn-icon-only btn-rounded me-3 btn-sm d-flex align-items-center justify-content-center btn-outline-success"
                  >
                    <i
                      class="bi bi-file-earmark-arrow-down"
                      style="font-size: 1.2rem"
                    ></i>
                  </button>
                  <div class="d-flex flex-column">
                    <h6 class="mb-1 text-sm text-dark">
                      {{
                        nota.id_tipo_documento == 606
                          ? "Nota Fiscal de Serviço"
                          : nota.id_tipo_documento == 633
                          ? "Nota SCM"
                          : nota.id_tipo_documento == 642
                          ? "Nota de locação"
                          : ""
                      }}
                    </h6>
                    <span class="text-xs"
                      >Emissão:
                      {{ nota.data_emissao.split("-").reverse().join("/") }}
                    </span>
                  </div>
                </div>
                <div
                  class="text-sm d-flex align-items-center text-gradient text-dark font-weight-bold ms-auto"
                  style="margin-right: 0.75rem !important"
                >
                  R$ {{ nota.valor_total }}
                </div>
                <button
                  class="btn btn-link text-dark text-sm mb-0 px-0 ms-4"
                  @click="BaixarNota(nota)"
                >
                  Baixar
                </button>
              </div>
              <div class="mt-3 mb-2" />
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <soft-button color="secondary" size="sm" @click="FecharModal"
            >Fechar</soft-button
          >
        </div>
      </div>
      {{ AbrirBotao }}
    </div>
    <Loading
      :active="isLoading"
      :is-full-page="fullPage"
      :loader="loader"
      :color="colorLoading"
    />
  </div>
</template>

<script>
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import SoftButton from "@/components/SoftButton.vue";
import bootstrap from "bootstrap/dist/js/bootstrap";
import { api } from "../../boot/axios";
import { ref } from "vue";

export default {
  emits: ["close"],
  props: {
    notas: Object,
    modal: Boolean,
  },
  components: {
    SoftButton,
    Loading,
  },
  data() {
    return {};
  },
  mounted() {
    this.modalNotas = new bootstrap.Modal(this.$refs.modalRefNotas);
  },
  methods: {
    AbrirModal() {
      this.modalNotas.show();
    },
    FecharModal() {
      this.modalNotas.hide();
      this.$emit("close");
    },
    BaixarNota(nota) {
      this.isLoading = true;
      let urlbnota =
        nota.id_tipo_documento == "642"
          ? "contrato/imprimirnotadebito"
          : "contrato/imprimirnota";
      api
        .post(urlbnota, { IdVenda: nota.id })
        .then((response) => {
          if (response.data.status == false) {
            console.log(response.data);
            //this.$notify({ type: "error", text: response.data.msg + " Entre em contato com o setor financeiro!", duration: 7000 });
          } else {
            if (response.data.Notabase64.toLowerCase().includes("fiscal")) {
              this.$notify({
                type: "error",
                text: "Nota indisponível para download! Entre em contato com setor financeiro!",
                duration: 7000,
              });
            } else {
              var data = Uint8Array.from(atob(response.data.Notabase64), (c) =>
                c.charCodeAt(0)
              );
              const blob = new Blob([data], { type: "octet/stream" });
              const url = URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.download = nota.id + ".pdf";
              link.click();
              URL.revokeObjectURL(url);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    AbrirBotao() {
      return this.modal ? this.AbrirModal() : "";
    },
  },
  setup() {
    const modalRefNotas = ref(null);
    let isLoading = ref(false);
    let fullPage = true;
    let loader = "spinner";
    let colorLoading = "#007BFF";

    return {
      modalRefNotas,
      isLoading,
      fullPage,
      loader,
      colorLoading,
    };
  },
};
</script>